@import "reset";
@import "variables";
@import "pagination";
@import "customer";
@import "loading";
@import "sidebar";
@import "table";
@import "form";
@import "tabs";

body {
  font-family: $font;
  font-size: 1.25em;
  color: $dark-gray;

  animation: fade-in 2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

b,
th,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-bold;
}

.main-container {
  margin-top: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  transition: margin-left 0.5s;

  &.active {
    transition: margin-left 0.5s;
    margin-left: calc(250px + 3rem);
  }
}

.custom-row {
  margin: 0 2rem;
  display: flex;
  flex-wrap: wrap;
}

.custom-col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.75rem 1rem;
  max-width: 100%;
  position: relative;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.centering {
  display: flex;
  justify-content: center;

  &::after {
    content: "";
    background-color: $dark-gray;
    opacity: 0.75;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 3;
  }
}

.flex-box {
  margin-top: 3rem;
  margin-bottom: 2rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  div {
    flex: 1;
    &.left {
      text-align: left;
    }
    &.middle {
      display: flex;
      text-align: center;
      justify-content: center;
    }
    &.right {
      text-align: right;
    }
  }
}

.logout {
  width: 150px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: 3rem;

  border: 0;
  border-radius: 20px;
  background-color: $primary;
  transition: background-color 0.3s;

  &:hover {
    background-color: $secondary;
    transition: background-color 0.3s;
  }
}
