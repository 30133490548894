@import "variables";

.tab {
  padding: 2rem;
}

.tab-list {
  border-bottom: 2px solid $light-gray;
}

.tab-list-item {
  border-bottom: 2px solid $light-gray;
  margin-bottom: -2px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: inline-block;
  list-style: none;

  &:hover,
  &.active {
    border-bottom: 2px solid $primary;
  }
}
