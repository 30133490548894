@import "variables";

.customer-detail {
  p {
    margin-bottom: 1rem;
  }

  .badge {
    width: 120px;
    color: #fff;
    padding: 0.25rem;
    text-align: center;
    border-radius: 20px;
    background-color: $primary;
  }
}

.customer-button {
  position: absolute;
  margin-top: -5rem;
  margin-left: 1rem;
  z-index: 2;

  .form-button {
    width: 140px;
    height: 35px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin: 1.5rem 0 2rem 1rem;

    border: 0;
    border-radius: 20px;
    background-color: $primary;
    transition: background-color 0.3s;

    &:hover {
      background-color: $secondary;
      transition: background-color 0.3s;
    }
  }
}

.tabs {
  transition: margin 0.5s;
  margin-left: 0;

  &.active {
    transition: margin 0.5s;
    margin-left: 250px;
  }
}

input[type="file"] {
  display: none;
}

.profile-pic {
  cursor: pointer;
  max-height: 300px;
  border-radius: 0.5rem;
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }
}
