@import url("https://fonts.googleapis.com/css?family=Eczar:800");
@import url("https://fonts.googleapis.com/css?family=Poppins:600");

.error {
  font-family: "Poppins";
  height: 100vh;
  background: #121212;
  overflow: hidden;

  #visual {
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}

.background-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-family: "Eczar";
    font-size: 60vmax;
    color: #282828;
    letter-spacing: 0.025em;
    margin: 0;
    transition: 750ms ease-in-out;
  }
}

.error a {
  border: 2px solid #555;
  margin: 1em;
  padding: 0.5em 0.8em;
  position: fixed;
  z-index: 1;
  color: #555;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 150ms;
  svg > polyline {
    transition: 150ms;
  }
  &:hover {
    color: #333;
    background: #dadada;
    border-color: transparent;
    svg > polyline {
      stroke: #000;
    }
    + .background-wrapper > h1 {
      color: #dadada;
    }
  }
}

.error p {
  color: #dadada;
  font-size: calc(1em + 3vmin);
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  margin: 0;
  text-align: right;
  text-shadow: -1px -1px 0 #121212, 1px 1px 0 #121212, -1px 1px 0 #121212,
    1px -1px 0 #121212;
  @media screen and (min-width: 340px) {
    width: 70%;
  }
  @media screen and (min-width: 560px) {
    width: 50%;
  }
  @media screen and (min-width: 940px) {
    width: 30%;
  }
  @media screen and (min-width: 1300px) {
    width: 25%;
  }
}
