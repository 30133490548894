@import "variables";

.warning {
  color: $red;
  margin-top: 4rem;
  font-size: 1.5rem;
  text-align: center;
  font-family: $font-bold;

  svg {
    height: 30px;
    width: 30px;
    margin-right: 0.5em;
  }
}

.loader {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-top: 4rem;
  border-radius: 50%;
  border: 8px solid $light-gray;
  border-top: 8px solid $primary;
  border-bottom: 8px solid $primary;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
