@import "variables";

.custom-table {
  width: 100%;
  color: $dark-gray;
  table-layout: fixed;
  white-space: nowrap;
  word-wrap: break-word;

  tr.highlight {
    color: $primary;
    font-family: $font-bold;
  }

  .clickable {
    cursor: pointer;

    svg .a {
      fill: $dark-gray;
      stroke: none;
    }

    &:hover svg .a {
      fill: $primary;
      stroke: none;
    }
  }

  tbody tr:hover {
    background-color: $light-gray;
  }

  td {
    padding: 1rem;
    border-top: 2px solid $light-gray;
  }

  th {
    padding: 1rem;
    text-align: left;
    border-top: none;
    border-bottom: 2px solid $light-gray;

    &.small {
      width: 100px;
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;

      &.ascending::after {
        content: url("../assets/icons/down.svg");
        display: inline-block;
        margin-left: 0.5em;
      }

      &.descending::after {
        content: url("../assets/icons/up.svg");
        display: inline-block;
        margin-left: 0.5em;
      }
    }
  }
}
