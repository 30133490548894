@import "variables";

.create-new {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 0.5rem;

    .a {
      fill: $primary;
      stroke: #fff;
    }

    &:hover .a {
      fill: $secondary;
    }
  }
}

.create-account {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: flex-end;

  svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 0.5rem;

    .a {
      fill: $primary;
      stroke: #fff;
    }

    &:hover .a {
      fill: $secondary;
    }
  }
}

input,
select,
textarea {
  padding: 0.5rem;
  color: $dark-gray;
  font-family: $font;
  font-size: 1.25rem;
  min-width: 60px;
  border-radius: 2px;
  border: 2px solid $light-gray;
  outline: 0;

  &:hover {
    border-bottom: 2px solid $primary;
  }

  &:focus {
    border: 2px solid $primary;
  }

  &::placeholder {
    color: $gray;
    font-family: $font;
    font-size: 1.25rem;
  }

  &.search-icon {
    width: 300px;
    padding-left: 2.5rem;
    background-image: url("../assets/icons/search.svg");
    background-repeat: no-repeat;
    background-position: 0.5rem center;
  }
}

.overlay {
  top: 50%;
  z-index: 9;
  width: 35%;
  position: absolute;
  border-radius: 10px;
  background-color: #fff;
  transform: translateY(-50%);
  border: 2px solid $light-gray;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  .right {
    margin-right: 2rem;
    text-align: right;
  }

  p {
    margin: 2rem;
    text-align: center;
  }

  h2 {
    margin: 2rem;
    text-align: center;
    font-size: 2rem;
  }

  label {
    display: block;
    color: $dark-gray;
    margin-bottom: 0.5rem;
  }

  input,
  select,
  textarea {
    width: 100%;
  }

  .close-button {
    top: 1rem;
    right: 1.25rem;
    cursor: pointer;
    position: absolute;

    &:hover {
      .a {
        fill: $red;
        stroke: $red;
      }
    }
    .a {
      fill: $dark-gray;
      stroke: $dark-gray;
    }
  }

  .form-button {
    width: 250px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin: 1.5rem 0 2rem 1rem;

    border: 0;
    border-radius: 20px;
    background-color: $primary;
    transition: background-color 0.3s;

    &:hover {
      background-color: $secondary;
      transition: background-color 0.3s;
    }
  }
}
