@import "variables";

.topbar {
  z-index: 9;
  height: 4rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    color: $dark-gray;
    margin-left: 2rem;
    font-size: 1.5rem;
    text-decoration: none;

    &:hover,
    .active {
      color: $primary;
    }
  }
}
