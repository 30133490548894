@import "variables";

.custom-pagination li {
  float: left;
  cursor: pointer;
  color: $dark-gray;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;

  svg .a {
    fill: $dark-gray;
    stroke: #fff;
  }

  &:hover svg .a {
    fill: $primary;
    stroke: #fff;
  }

  &.active {
    background-color: $primary;
    color: white;
  }

  &:hover:not(.active):not(.arrow) {
    background-color: $light-gray;
  }
}
