@import url("https://fonts.googleapis.com/css?family=Eczar:800");
@import url("https://fonts.googleapis.com/css?family=Poppins:600");

.redirect {
  height: 100vh;
  color: #dadada;
  background: #121212;
  font-family: "Poppins";
  text-align: center;
  overflow: hidden;

  h1 {
    top: 40%;
    margin-bottom: 2rem;
    position: relative;
  }

  p {
    top: 40%;
    margin-bottom: 2rem;
    position: relative;
  }

  a {
    top: 40%;
    position: relative;
    text-decoration: none;
    color: #dadada;

    &:hover {
      color: #05acef;
    }
  }
}
