$primary: #05acef;
$secondary: #0088c6;
$tertiary: #05acef1a;
$red: #ff0000;
$dark-gray: #252525;
$gray: #bababa;
$light-gray: #ececec;
$font: "CorpoS", Arial, sans-serif;
$font-bold: "CorpoS_Bold", Arial, sans-serif;

@font-face {
  font-family: "CorpoS";
  src: url("../assets/fonts/CorpoS.ttf") format("truetype");
}

@font-face {
  font-family: "CorpoS_Bold";
  src: url("../assets/fonts/CorpoS_Bold.ttf") format("truetype");
}
